// Your variable overrides
$body-color: #373a3c;
body {
  background-color: #e5e5e5 !important;
} 

$card-spacer-y: 0.5rem;

.form-group.required .col-form-label:after {
  content: '*';
  margin-left: 4px;
  color: #dc3545;
}

.form-check-input {
  background-color: transparent !important;

  &:checked {
    background-color: #0d6efd !important;
  }
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: transparent !important;
}

button {
  &.calendar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}

// Don't show icons in validated form fields
.form-control {
  background-image: none !important;
  background-color: transparent !important;
}

.form-control.is-valid {
  border-color: #ced4da !important;
}

.pointer {
  cursor: pointer;
}

body,
html {
  height: 100%;
}
.rounded-xl,
.modal-content {
  border-radius: 0.5rem !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


  &.badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  &.badge-success {
    color: #fff;
    background-color: #2DA745;
  }
  &.badge-danger {
    color: white;
    background-color: #DC3545;
  }
}

.p-datatable .p-sortable-column-icon {
  float: right;
}

.p-datatable {
  .p-sortable-column {
    &.p-highlight {
      color: black !important;

      i {
        color: black !important;
      }

    }
  }
}

.p-datatable-thead {
  tr {
    border: 1px solid #eaeaea !important;
    th {
      background-color: white !important;
      font-size: 0.9em !important;
      border: unset !important;
    }
  }
}
.p-datatable-tbody {
  tr {
    border: 1px solid #eaeaea !important;
    td {
      font-size: 0.9em;
      border: unset !important;
    }
  }
}

.p-accordion .p-accordion-content {
  border: 0 !important;
  background-color: #FCFCFC !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  height: 40px;
  font-size: 0.9em;
}

.fluid-profile {
  height: auto;
  width: 200px;
}

body .p-paginator {
  background-color: transparent !important;
  border: none !important;
  margin-top: 20px !important;
}

// ng-select
ng-select {
  .ng-dropdown-panel {
    width: auto !important;
    max-width: 600px;
    min-width: 138px;
  }
}

#toast-container {
  position: fixed;
  right: 16px;
}

.page-link {
  background-color: white !important;
}