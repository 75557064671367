// Your global overrides for bootstrap and theme

.btn-primary {
  background-color: #0275D8;
}

.btn-primary:hover {
  background-color: #025AA5;
}

body .ui-accordion .ui-accordion-header a {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  order: 1;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #0275D8;
}

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #ffffff;
}

.text-left {
  text-align: left !important;
}

body .ui-table .ui-table-tbody > tr,
body .ui-table .ui-table-thead > tr {
  border: 1px solid #eceeef;
}

body .ui-table .ui-table-tbody > tr > td,
body .ui-table .ui-table-thead > tr > th {
  border: none;
  background-color: #ffffff;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #0275D8;
}

// Style for controls

.form-control::placeholder {
  color: #999999;
}

select.ng-pristine {
  color: #999999;
}

select:focus {
  color: #495057;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f9f9f9;
  border-color: #dcdcdc;
  color: #5d5d5d;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d5d5d;
    opacity: 1; /* Firefox */
  }

}

.custom-border-error-input input {
  border-color: #dc3545;
}

.custom-border-error-select .ng-select-container {
  border-color: #dc3545;
}

body .ui-chkbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

body .ui-chkbox .ui-chkbox-box {
  width: 18px;
  height: 18px;
}

body .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border-color: transparent;
}

body .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}

body .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover {
  border-bottom: 3px solid #0275D8;
}

body .nav-tabs .nav-item {
  margin-bottom: -2px;
}

body .nav-tabs .nav-link {
  padding-top: 0;
}

// Ng-select custom styles
.custom-select-ng-container ::ng-deep .ng-select-container{
  border-radius: 0 5px 5px 0;
  min-height: 38px;
}

.custom-select-ng ::ng-deep .ng-value-icon {
  background-color: #0275D8;
  border-radius: 5px 0 0 5px;
  color: white;
  border-right: 0 !important;
  padding: 5px 5px 5px 10px !important;
}

.custom-select-ng ::ng-deep .ng-value-label {
  background-color: #0275D8;
  border-radius: 0 5px 5px 0;
  padding: 5px 10px 5px 5px !important;
  color: white;
}

.custom-pre-wrap {
  white-space: pre-wrap;
}

.form-control-clear {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 20%;
  background-color: white;
}

.email-length {
  overflow-wrap: break-word;
}

.no-highlight {
  color: black !important;
}

/** Pagination */
.page-link {
  border: none;
  padding: 10px 15px 10px 15px;
}

.page-item.active .page-link {
  color: #575757;
  background-color: white;
}

ngb-pagination ul {
  margin-bottom: 0;
}

app-pagination > div > div.col-12.col-md-3.d-flex.align-items-center.justify-content-start > p {
  margin-bottom: 0;
}
/** Pagination end */
